import { capitalizeFirstLetter } from "@/helpers/dataHelper";
import useWidth, { IBooleanArgs } from "@/helpers/widthUtil";
import Image from "next/image";
import Link from "next/link";
import { memo } from 'react';

const BlogItem = ({ data }) => {
    const { isMobile }: IBooleanArgs = useWidth();
    return (
      <div
        className={
          isMobile
            ? 'd-flex justify-content-center col-sm-12'
            : 'col-lg-3 col-md-4 rounded cursor-pointer'
        }
        key={data?.id}
      >
        <div className="blog-item">
          <div className="blog-image">
            <Link href={`/blog/${data.slug}`} prefetch={false}>
              <a className="mb-0 pb-0">
                <Image
                  src={data?.photo ? data?.photo : '/images/blog.jpg'}
                  alt="image"
                  width="500px"
                  height="230px"
                />
              </a>
            </Link>
          </div>

          <div className="single-blog-item">
            <ul className="blog-list">
              <li>
                <i className="fa fa-user-alt"></i>{' '}
                {capitalizeFirstLetter(data?.author?.name)}
              </li>
            </ul>

            <div className="blog-content">
              <div className="d-flex justify-content-between">
                <Link href={`/blog/${data.slug}`} prefetch={false}>
                  <a>
                    <h3 className="blog-single-name">{data.title}</h3>
                  </a>
                </Link>
              </div>
              <div
                dangerouslySetInnerHTML={{
                  __html: data.meta_description,
                }}
                className="blog-description"
              ></div>
            </div>
          </div>
        </div>
      </div>
    );
};

export default memo(BlogItem);