import Head from 'next/head';
import { useEffect } from 'react';

import '../../public/styles/Apps.scss';

// Font-awesome
import '@fortawesome/fontawesome-free/js/brands';
import '@fortawesome/fontawesome-free/js/fontawesome';
import '@fortawesome/fontawesome-free/js/regular';
import '@fortawesome/fontawesome-free/js/solid';

import NProgress from 'nprogress'; //nprogress module
import '../../public/styles//nprogress.css'; //styles of nprogress

import { Provider, useSelector, useStore } from 'react-redux';
import { wrapper } from '../../store';
// import { persistStore } from 'redux-persist';
import useWidth from '@/helpers/widthUtil';
import { Button } from 'antd';
import { MathJaxContext } from 'better-react-mathjax';
import PersistWrapper from 'next-persist/lib/NextPersistWrapper';
import Link from 'next/link';
import Router from 'next/router';

import dynamic from 'next/dynamic';
const UserLiveTrack = dynamic(() => import('./UserLiveTrack'));
const GoTop = dynamic(() => import('@/components/layout/landing/Top'), {
  ssr: false,
});

function App({ Component, pageProps }) {
  const store = useStore();
  // const persistor = persistStore(store);
  const { isMobile } = useWidth();
  Router.events.on('routeChangeStart', () => NProgress.start());
  Router.events.on('routeChangeComplete', () => NProgress.done());
  Router.events.on('routeChangeError', () => NProgress.done());

  const npConfig = {
    method: 'localStorage',
  };

  const hide = () => {
    const cookieBan = document.getElementById('cookieBanner');
    window.localStorage.setItem('accept-cookie', true);
    cookieBan.style.display = 'none';
  };

  useEffect(() => {
    const acceptCookie = window.localStorage.getItem('accept-cookie');
    const cookieBan = document.getElementById('cookieBanner');
    if (acceptCookie === null && !isMobile) {
      cookieBan.style.display = 'block';
    }
  }, []);
  const config = {
    loader: { load: ['[tex]/html'] },
    tex: {
      packages: { '[+]': ['html'] },
      inlineMath: [
        ['$', '$'],
        ['\\(', '\\)'],
      ],
      displayMath: [
        ['$$', '$$'],
        ['\\[', '\\]'],
      ],
    },
  };
  const auth = useSelector((state) => state?.auth?.creds?.user_data);

  return (
    <>
      {/* <PersistGate persistor={persistor} loading={<div>Loading...</div>}> */}
      {!isMobile && (
        <span id="cookieBanner">
          <div className="cookie-banner rounded shadow p-4 d-flex flex-column justify-content-space-between bg-white">
            <span className="cookie-banner-message text-dark">
              This website uses cookies to ensure you get the best experience & by
              continuing to use our website, you agree to our{' '}
              <Link href="/privacy-policy">
                <a>
                  <span role="button" className="text-primary">
                    Privacy and Cookie Policy.
                  </span>
                </a>
              </Link>
            </span>
            <div className="cookie-banner-compliance mx-auto mt-2">
              <Button
                className="btn-secondary border-danger border-14 rounded"
                tabIndex="2"
                onClick={hide}
              >
                Got it!
              </Button>
            </div>
          </div>
        </span>
      )}
      <Provider store={store}>
        <PersistWrapper wrapperConfig={npConfig}>
          <Head>
            <title>Learnsic</title>
          </Head>
          <MathJaxContext version={3} config={config}>
            {(auth?.user_type === 'client' || auth?.user_type === 'instructor') && (
              <UserLiveTrack />
            )}
            <Component {...pageProps} />
          </MathJaxContext>
          <GoTop
          // scrollStepInPx="100"
          // delayInMs="15.50"
          />
        </PersistWrapper>
      </Provider>
      {/* </PersistGate> */}
    </>
  );
}

export default wrapper.withRedux(App);
