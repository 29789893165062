import Footer from "@/components/layout/landing/Footer";
import NavbarNew from "@/components/layout/landing/NavbarNew";

const LandingLayout = ({ children }) => {
    return (
        <>
            <NavbarNew />
            {children}
            <Footer />
        </>
    )
};

export default LandingLayout;